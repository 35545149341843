// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  salesBaseUrl: 'https://dev-shipping-sales.acrossexpress.com', // 'http://localhost', // 'https://dev-shipping-sales.acrossexpress.com',
  transportationBaseUrl: 'https://dev-transportation-management.acrossexpress.com', // 'http://localhost:82', // 'https://dev-transportation-management.acrossexpress.com',
  fleetManagementBaseUrl: 'https://fleet-management.acrossexpress.com', // 'http://3.122.101.91:3000',
  firebaseConfig: {
    apiKey: 'AIzaSyDy6SsMOna203PsVK02DQhieioUWCzbHKg',
    authDomain: 'beam-africa-development.firebaseapp.com',
    projectId: 'beam-africa-development',
    storageBucket: 'beam-africa-development.appspot.com',
    messagingSenderId: '80869657661',
    appId: '1:80869657661:web:0d291bfc27d08fce236e54',
    measurementId: 'G-YKMD8Y19T3'
  },
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
