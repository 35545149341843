import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Request, Location, Trip, Event, TripStatus } from './app.component';

@Injectable({
  providedIn: 'root'
})
export class TrackingApiService {

  private requestsUrl = `${environment.salesBaseUrl}/requests`;
  private tripsUrl = `${environment.transportationBaseUrl}/trips`;
  private assetsUrl = `${environment.fleetManagementBaseUrl}/assets`;

  constructor(private http: HttpClient) { }

  getRequestById(id: string): Observable<Request> {
    return this.http.get<RequestFromApi>(`${this.requestsUrl}/${id}`).pipe(
      map(r => this.mapRequestFromApiToRequest(r)),
      catchError(this.handleError)
    );
  }

  getTripsByRequestId(id: string): Observable<Trip[]> {
    const url = `${this.tripsUrl}/request/${id}`;
    return this.http.get<TripFromApi[]>(url).pipe(
      mergeMap(trips => {
        return Promise.all(trips.map(trip => this.mapTripFromApiToTrip(trip)));
      }),
      catchError(this.handleError)
    );
  }

  getLatestAssetLocation(assetId: number): Observable<{ latitude: number; longitude: number; recordedAt: Date; }> {
    return this.http.get<any>(`${this.assetsUrl}/${assetId}/latestLocation`).pipe(
      map(r => { r.recordedAt = new Date(r.recordedAt); return r; }),
      catchError(this.handleError)
    );
  }

  private mapRequestFromApiToRequest(r: RequestFromApi): Request {
    const req: Request = {
      id: r.id,
      pickup: {
        location: r.pickup.location,
        date: r.pickup.date as Date,
      },
      dropoff: {
        location: r.dropoff.location,
        date: r.dropoff.date as Date,
      },
      createdAt: r.createdAt,
      weight: r.weight,
    }
    return req;
  }

  private mapTripFromApiToTrip(trip: TripFromApi): Trip {
    const t: Trip = {
      id: trip.id,
      number: trip.number,
      requestId: trip.requestId,
      purchaseOrderNumber: trip.purchaseOrderNumber,
      assetId: trip.liveTrackingAssetId,
      status: this.mapTripStatusFromApiToTripStatus(trip.status),
      weight: trip.weight,
      description: trip.description,
      // contacts: trip.contacts,
      documents: null,
      lastLocation: trip.lastLocation,
      lastLocationUpdateTime: new Date(trip.lastLocationUpdateTime),
      weightAtLoading: trip.weightAtLoading,
      weightAtUnloading: trip.weightAtUnloading,
      createdAt: trip.createdAt,
      eventCount: 0,
      events: undefined,
      // Get and populate these properties
      // driver: undefined,
      // vehicle: undefined,
      // trailerVehicle: undefined,
    };
    if (trip.events && Array.isArray(trip.events)) {
      t.events = trip.events?.map(e => this.mapEventFromApiToEvent(e));
      t.eventCount = t.events.length;
    }
/*     if (trip.documents && Array.isArray(trip.documents)) {
      const docs: string[] = [];
      trip.documents.forEach(d => {
        docs.push(...d.links.map(l => l.url));
      });
      t.documents = docs;
    }
    if (trip.driverId) {
      const d = await this.carrierService.getDriver(trip.driverId).toPromise().catch(err => {
        console.error(err);
        return undefined;
      });
      if (d) {
        t.driver = {
          id: d.id,
          name: d.name,
          phoneNumber: d.phoneNumber,
          licenseNumber: d.licenseNumber,
        };
      }

    }
    if (trip.vehicleId) {
      const v = await this.carrierService.getVehicle(trip.vehicleId).toPromise().catch(err => {
        console.error(err);
        return undefined;
      });
      if (v) {
        t.vehicle = {
          id: v.id,
          isTrailer: false, //  needs co v.isTrailer,
          plateNumber: v.plateNo,
          trailer: null,
        };
      }
    }
    if (trip.trailerVehicleId) {
      const v = await this.carrierService.getVehicle(trip.trailerVehicleId).toPromise().catch(err => {
        console.error(err);
        return undefined;
      });
      if (v) {
        t.trailerVehicle = {
          id: v.id,
          isTrailer: true, //  needs co v.isTrailer,
          plateNumber: v.plateNo,
          driverVehicle: null,
        };
      }
    } */
    return t;
  }

  private mapTripStatusFromApiToTripStatus(status: TripStatusFromApi): TripStatus {
    switch(status) {
      case 'PENDING':
      case 'AT_PICKUP': 
      case 'LOADING': 
        return 'AT_PICKUP';
      case 'LOADED': 
        return 'LOADED';
      case 'IN_TRANSIT': 
      case 'AT_DROPOFF': 
      case 'UNLOADING': 
        return 'IN_TRANSIT';
      case 'UNLOADED': 
        return 'UNLOADED';
      case 'COMPLETED': return 'COMPLETED';
      case 'CANCELLED': return 'CANCELLED';
    }
  }

  private mapEventFromApiToEvent(event: EventFromApi): Event {
    const e: Event = {
      id: event.id,
      name: event.title,
      date: new Date(event.date),
      location: event.location,
      description: event.description,
      // documents: mapDocumentsFromApiToDocument(event.documents),
      trip: undefined,
    };
    return e;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error({ error });
    // Check for network error
    if (error.status === 0 && error.headers.keys().length === 0) {
      // Possible additional checks
      // error.error instanceof Event && error.error.type === "error" && error.statusText === "Unknown Error"
      // Return an observable with a user-facing error message.
      return throwError('Network error; please check your connection and try again.');
    }
    else if (error.status === undefined) {
      // A client-side error. Something like parsing or other errors. Handle it accordingly.
      console.error('An client-side error occurred:', error.message);
      // Return an observable with a user-facing error message.
      return throwError('Something bad happened; please try again later.');
    } else if (error.status > 0) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      // Return an observable with a user-facing error message.
      return throwError(error.error.message);
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}

interface RequestFromApi {
  id: string;
  // shipper: Shipper;
  // handler?: Handler;
  // status: RequestStatus;
  // declinedAt?: Date;
  // completedAt?: Date;
  // cancelledAt?: Date;
  pickup: Appointment;
  dropoff: Appointment;
  weight: number;
  distance?: number;
  description?: string;
  // goodsType?: string;
  // vehicleTypes?: { vehicleType: string, quantity?: number }[];
  // responses?: ResponseForShippingRequest[];
  createdAt: Date;
  // documents: DocumentFromApi[];
  // preferredPricing: PricingDto,
}

interface Appointment {
  date: Date | DateRange;
  location: Location;
}

interface DateRange {
  start: Date;
  end: Date;
}

interface TripFromApi {
  id: number;
  requestId: string;
  number: number;
  purchaseOrderNumber: number;
  liveTrackingAssetId?: number;
  weight: number;
  weightAtLoading?: number;
  weightAtUnloading?: number;
  lastLocation?: Location;
  lastLocationUpdateTime?: Date;
  driverId: number;
  vehicleId: number;
  trailerVehicleId?: number;
  events: EventFromApi[];
  // documents?: DocumentFromApi[];
  // contacts?: ContactFromApi[];
  status: TripStatusFromApi;
  description?: string;
  createdAt: Date;
}
type TripStatusFromApi = 'PENDING' | 'AT_PICKUP' | 'LOADING' | 'LOADED' | 'IN_TRANSIT' | 'AT_DROPOFF' | 'UNLOADING' | 'UNLOADED' | 'COMPLETED' | 'CANCELLED';

interface EventFromApi {
  id: number;
  title: string;
  date: string;
  location?: Location;
  description?: string;
  // documents?: DocumentFromApi[];
}

/* interface DocumentFromApi {
  links: LinkFromApi[];
  caption?: string;
}

interface LinkFromApi {
  url: string;
  caption?: string;
} */

/* interface ContactFromApi {
  name?: string;
  phone: string;
  relevance: string;
} */
