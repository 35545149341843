import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, from } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private angularFireAuth: AngularFireAuth) { }

  get IsLoggedIn(): Observable<boolean> {
    return this.angularFireAuth.user.pipe(map(user => user ? true : false));
  }

  loginWithEmailAndPassword(email: string, password: string): Observable<boolean> {
    const signInPromise = this.angularFireAuth.signInWithEmailAndPassword(email, password)
    .then(userCredential => {
      if (userCredential.user) {
        return true;
      } else {
        return false;
      }
    });
    return from(signInPromise);
  }

  logout(): Observable<void> {
    return from(
      this.angularFireAuth.signOut()
    );
  }

  getAuthorizationToken(): Observable<string> {
    const token = this.angularFireAuth.idToken;
    return token;
  }
}
