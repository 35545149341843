import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

import { combineLatest, EMPTY, from, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TrackingApiService } from './tracking-api.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'live-tracking';
  updatesPanelOpenState = false;
  trackingCode: string;
  searchDone = false;
  hasLastLocation = false;
  atPickup = false;
  atDropoff = false;

  request$: Observable<Request>;
  trips$: Observable<Trip[]>;
  selectedTrip$: Observable<Trip>;
  latestLocation$: Observable<{latitude: number, longitude: number, recordedAt: Date}>;
  currentAddress$: Observable<string>;
  eventsGroupedByDate: Map<string, Event[]> = new Map();
  latestEvent: Event;

  @ViewChild(GoogleMap) googleMap: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  showMap = false;
  pickupMarkerOptions: google.maps.MarkerOptions = { draggable: false, icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' };
  dropoffMarkerOptions: google.maps.MarkerOptions = { draggable: false, icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' };
  pickupMarkerPosition$: Observable<google.maps.LatLngLiteral>;
  dropoffMarkerPosition$: Observable<google.maps.LatLngLiteral>;
  currentMarkerOptions: google.maps.MarkerOptions = { draggable: false, icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' };
  currentPosition$: Observable<google.maps.LatLngLiteral & { recordedAt: Date }>;

  mapCenter: google.maps.LatLng = new google.maps.LatLng(9.016274526428973, 38.79074495617295);
  mapOptions: google.maps.MapOptions = {
    gestureHandling: 'cooperative'
  };
  sub: Subscription;

  readonly steps = ['At pickup', 'Loaded', 'In Transit', 'Unloaded', 'Completed'];
  tripStep$: Observable<0 | 1 | 2 | 3 | 4 | -1>; // 0-4 represent the steps -1 represents 'Cancelled'

  constructor(private service: TrackingApiService, private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.IsLoggedIn.subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        this.authService.loginWithEmailAndPassword('live-tracking@serviceaccounts.acrossexpress.com', 'd8UwO).{QoH8');
      }
    });

    const params = new URLSearchParams(window.location.search.substring(1));
    const code = params.get('code');
    if (code && /^([A-Z]|[0-9]){8}-[1-9]{1}\d{0,2}$/.test(code.trim())) {
      this.trackingCode = code.trim();
      this.searchShipment();
    }
  }


  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  searchShipment(): void {
    // Reset flags
    this.searchDone = false;
    this.showMap = false;
    this.hasLastLocation = false;
    this.atPickup = false;
    this.atDropoff = false;

    const parts = this.trackingCode.split('-');
    if (parts.length !== 2) {
      return;
    }
    const requestId = parts[0];
    const tripNumber = parts[1];

    this.request$ = this.service.getRequestById(requestId).pipe(tap((r) => {
      this.searchDone = true;
      if (r) {
        this.showMap = true;
      }
    }), shareReplay(), catchError((err) => { this.searchDone = true; return throwError(err); }));
    this.trips$ = this.service.getTripsByRequestId(requestId);
    this.selectedTrip$ = this.trips$.pipe(
      map(trips => trips.find(t => t.number === +tripNumber)),
      filter(t => {
        if (t) {
          return true;
        }
      }),
      tap(t => {
        if (t && t.events && t.events.length > 0) {
          this.eventsGroupedByDate = new Map();
          this.latestEvent = null;
          t.events.sort((a, b) => a.date.getTime() - b.date.getTime());
          this.latestEvent = t.events[t.events.length - 1];
          t.events.forEach(v => {
            const events = this.eventsGroupedByDate.get(v.date.toDateString());
            if (!events) {
              this.eventsGroupedByDate.set(v.date.toDateString(), [v]);
            } else {
              events.push(v);
            }
          });
          return;
        }
        if (t && !t.events) {
          t.events = [];
        }
      }),
      shareReplay()
    );
    this.latestLocation$ = this.selectedTrip$.pipe(switchMap(t => {
      if (t.assetId) {
        return this.service.getLatestAssetLocation(t.assetId);
      } else if (t.lastLocation?.address) {
        if (t.lastLocation?.coordinate?.latitude) {
          this.hasLastLocation = true;
          return of({
            latitude: t.lastLocation.coordinate.latitude,
            longitude: t.lastLocation.coordinate.longitude,
            recordedAt: t.lastLocationUpdateTime,
          });
        }
      } else if (t.status === 'AT_PICKUP' || t.status === 'LOADED') {
        this.atPickup = true;
      } else if (t.status === 'COMPLETED' || t.status === 'UNLOADED') {
        this.atDropoff = true;
      }
      return EMPTY;
    }), shareReplay());
    this.tripStep$ = this.selectedTrip$.pipe(map(t => {
      switch (t.status) {
        case 'AT_PICKUP': return 0;
        case 'LOADED': return 1;
        case 'IN_TRANSIT': return 2;
        case 'UNLOADED': return 3;
        case 'COMPLETED': return 4;
        case 'CANCELLED': return -1;
      }
    }));
    this.currentAddress$ =  this.latestLocation$.pipe(switchMap(l => {
      return from(
        new Promise((resolve: (v: string) => void, reject) => {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            location: { lat: l.latitude, lng: l.longitude},
          }, (res, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
              return reject(status);
            }
            const city = res.find(r => r.types.includes('political') && r.types.includes('locality'));
            if (city) {
              return resolve(city.formatted_address);
            }
            const exclusive = res.filter(
              r => !(r.types.length === 1 && (
                r.types.includes('street_address') ||
                r.types.includes('route') ||
                r.types.includes('plus_code'))
              )
            );
            if (exclusive && exclusive.length > 0) {
              return resolve(exclusive[0].formatted_address);
            }
            return resolve(res[0].formatted_address);
          });
        })
      );
    }));

    this.pickupMarkerPosition$ = this.request$.pipe(
      map(r => ({
        lat: r.pickup.location.coordinate.latitude,
        lng: r.pickup.location.coordinate.longitude,
      }))
    );
    this.dropoffMarkerPosition$ = this.request$.pipe(
      map(r => ({
        lat: r.dropoff.location.coordinate.latitude,
        lng: r.dropoff.location.coordinate.longitude,
      }))
    );
    this.currentPosition$ = this.latestLocation$.pipe(
      map(v => ({ lat: v.latitude, lng: v.longitude, recordedAt: v.recordedAt}))
    );

    const positions = combineLatest([
      this.pickupMarkerPosition$,
      this.dropoffMarkerPosition$,
      this.currentPosition$.pipe(startWith(0))
    ]).pipe(
      map(([pickupMarkerPosition, dropoffMarkerPosition, currentPosition]) => {
        if (pickupMarkerPosition.lat && dropoffMarkerPosition.lat) {
          // center the markers displayed on map
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(pickupMarkerPosition);
          bounds.extend(dropoffMarkerPosition);
          if (currentPosition && typeof currentPosition === 'object' && currentPosition.lat) {
            bounds.extend({lat: currentPosition.lat, lng: currentPosition.lng});
          }
          this.googleMap.fitBounds(bounds);
        }
      })
    );
    this.sub = positions.subscribe();
  }

  openInfoWindow(marker: MapMarker): void {
    this.infoWindow.open(marker);
  }
}

export interface Request {
  id: string;
  // shipper: Shipper;
  // handler?: Handler;
  // status: RequestStatus;
  // declinedAt?: Date;
  completedAt?: Date;
  cancelledAt?: Date;
  pickup: Appointment;
  dropoff: Appointment;
  weight: number;
  distance?: number;
  // preferredPricing?: Pricing;
  description?: string;
  // goodsType?: string;
  // vehicleTypes?: { vehicleType: string, quantity?: number }[];
  // responses?: ResponseForShippingRequest[];
  createdAt: Date;
  // documents?: string[];
}

/* export type RequestStatus =
    'SUBMITTED' |
    'APPROVED' |
    'DECLINED' |
    'NEEDS_CLARIFICATION' |
    'CANCELLED' |
    'CONFIRMED' |
    'COMPLETED'; */

export interface Location {
  coordinate?: Coordinate;
  address: string; // formatted_address from google maps
  localAddress?: string; // this is the name used by the local people to identify it
}

export interface Coordinate {
  latitude: number;
  longitude: number;
}

export interface Appointment {
  date: Date;
  location: Location;
}

export interface Trip {
  id: number;
  number: number;
  requestId: string;
  purchaseOrderNumber: number; // NOTE: Maybe changed to required
  assetId?: number;
  weight: number;
  weightPercentage?: number;
  lastLocation?: Location;
  lastLocationUpdateTime?: Date;
  // vehicle: DriverVehicle;
  // trailerVehicle?: TrailerVehicle;
  // driver: Driver;
  eventCount?: number;
  events?: Event[];
  documents?: string[];
  // contacts?: Contact[];
  weightAtLoading?: number;
  weightAtUnloading?: number;
  status: TripStatus;
  completedAt?: Date;
  cancelledAt?: Date;
  createdAt: Date;
  description?: string;
}

export type TripStatus =  'AT_PICKUP' | 'LOADED' | 'IN_TRANSIT' | 'UNLOADED' | 'COMPLETED' | 'CANCELLED';

export interface Event {
  id: number;
  name: string;
  date: Date;
  location: Location;
  description?: string;
  documents?: string[];
  trip: Trip;
}
