<div class="wrapper">   
   <ol class="ProgressBar">
     <li class="ProgressBar-step" *ngFor="let step of steps; index as i" [class.is-current]="i === currentStep" [class.is-complete]="i < currentStep || (i === currentStep && currentStepCompleted)">
       <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
       <span class="ProgressBar-stepLabel">{{ step }}</span>
     </li>
   </ol>
</div>
   
<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="checkmark-bold" viewBox="0 0 24 24">
        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
    </symbol>
</svg>