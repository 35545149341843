<div class="container">
  <mat-toolbar class="mat-elevation-z4" style="position: relative; z-index: 10; flex-shrink: 0;" color=primary>
    <span style="margin-right: 20px;"> Across Express </span>
    <span> Live Track </span>
  </mat-toolbar>
  <div class="flex-container">
    <h2 class="page-header">
      Track Your Shipment in Real Time
    </h2>
    <form #form="ngForm" (ngSubmit)="searchShipment()">
      <mat-form-field appearance="outline" style="margin-bottom: 18px;">
        <mat-label>Tracking Code</mat-label>
        <input matInput [(ngModel)]="trackingCode" 
        placeholder="XRM89L1F-3" name="trackingCode" #trackingCodeM="ngModel" 
        type="text" maxlength="12" pattern="^([A-Z]|[0-9]){8}-[1-9]{1}\d{0,2}$" required>
        <button type="submit" mat-icon-button matSuffix [disabled]="form.invalid">
          <mat-icon>search</mat-icon>
        </button>
        <mat-error *ngIf="trackingCodeM.invalid && trackingCodeM.errors.required">Tracking Code is required</mat-error>
        <mat-error *ngIf="trackingCodeM.invalid && trackingCodeM.errors.maxlength">Name must be at most 12 characters long</mat-error>
        <mat-error *ngIf="trackingCodeM.invalid && trackingCodeM.errors.pattern">Invalid Tracking Code</mat-error>
      </mat-form-field>
    </form>
  
    <div *ngIf="request$ | async as request; else requestNotFound">
      <!-- Request Addresses and Trip Status -->
      <div class="mat-elevation-z1" style="background-color: white; margin-bottom: 40px;">
        <div class="addresses">
          <div>
            <p class="label">From</p>
            <p class="value">{{ request.pickup.location.address }}</p>
          </div>
          <div>
            <p class="label">At</p>
            <p class="value" *ngIf="currentAddress$ | async as currentAddress; else unknownCurrentAddress">{{ currentAddress }}</p>
            <ng-template #unknownCurrentAddress>
              <p class="value" *ngIf="hasLastLocation && selectedTrip$ | async as selectedTrip">{{ selectedTrip.lastLocation.address }}</p>
              <p class="value" *ngIf="!hasLastLocation && atPickup">{{ request.pickup.location.address }}</p>
              <p class="value" *ngIf="!hasLastLocation && atDropoff">{{ request.dropoff.location.address }}</p>
              <p class="value" *ngIf="!hasLastLocation && !atPickup && !atDropoff">Unknown</p>
            </ng-template>
          </div>
          <div>
            <p class="label">To</p>
            <p class="value">{{ request.dropoff.location.address }}</p>
          </div> 
        </div>
        <div *ngIf="{ value: tripStep$ | async } as tripStep">
          <ng-container *ngIf="tripStep.value || tripStep.value === 0">
            <app-dot-check-progress-bar  *ngIf="tripStep.value !== -1"
              [steps]="steps"
              [currentStep]="tripStep.value">
            </app-dot-check-progress-bar>
            <div *ngIf="tripStep.value === -1">
             Trip Cancelled
            </div>
          </ng-container>
        </div>
      </div>
  
      <!-- Latest Updates(Events) -->
      <div *ngIf="selectedTrip$ | async as selectedTrip" style="margin-bottom: 40px;">
        <mat-expansion-panel *ngIf="eventsGroupedByDate.size > 0"
          (opened)="updatesPanelOpenState = true"
          (closed)="updatesPanelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title style="text-transform: uppercase;">
              Recent Updates
            </mat-panel-title>
            <mat-panel-description *ngIf="!updatesPanelOpenState">
              {{ latestEvent?.name }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="events-content">
              <div class="event-group" *ngFor="let eventsForDate of eventsGroupedByDate | keyvalue">
                  <div>
                      <p class="overline"> {{ eventsForDate.key | date:'EEEE' }} </p>
                      <p> {{ eventsForDate.key | date }} </p>
                  </div>
                  <div>
                      <div class="event" *ngFor="let event of eventsForDate.value;">
                        <div>
                          <p class="value">{{ event.name }}</p>
                          <p><span> {{ event.date | date:'shortTime' }} </span><ng-container *ngIf="event.location?.address"> · <span> {{ event.location.address }} </span> </ng-container></p>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </mat-expansion-panel>
      </div>
  
    </div>
    <ng-template #requestNotFound>
      <div *ngIf="searchDone">
        <h3>Shipment not found</h3>
      </div>
    </ng-template>
    <!-- Map -->
    <div class="map" 
      [ngStyle]="showMap ? {'visibility': 'visible', 'position': 'static'} : { 'visibility': 'hidden', 'position': 'absolute'}"
      style="margin-bottom: 50px;">
      <google-map class="map" height=380px width="100%" [center]="mapCenter" [zoom]="8" [options]="mapOptions" #map>
          <map-marker *ngIf="pickupMarkerPosition$ | async as pickupMarkerPosition" 
            [position]="pickupMarkerPosition" 
            [options]="pickupMarkerOptions">
          </map-marker>
          <map-marker *ngIf="dropoffMarkerPosition$ | async as dropoffMarkerPosition" 
            [position]="dropoffMarkerPosition"
            [options]="dropoffMarkerOptions">
          </map-marker>
          <ng-container *ngIf="currentPosition$ | async as currentPosition">
              <map-marker 
                [position]="currentPosition" 
                [options]="currentMarkerOptions"
                #marker="mapMarker" (mapClick)="openInfoWindow(marker)">
              </map-marker>
              <map-info-window>Recorded at {{ currentPosition.recordedAt.toString() }} </map-info-window>
          </ng-container>
      </google-map>
    </div>
  </div>
  <footer>
    <div>
      Across Express
    </div> 
    <div>
      <mat-icon style="vertical-align: middle; margin-right: 5px;">phone</mat-icon>
      <span>6984</span>
    </div>
  </footer>
</div>
